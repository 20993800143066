@import 'vars';

.delete-list-item {
  position: absolute;
  top: 0;
  right: 1px;
}

.list {
  @include block;
  @include marB;
}

.list-item {
  &:nth-child(even) {
    background-color: $color-block-light;
  }

  &:nth-child(odd) {
    @include white-bkg;
  }

  background-color: $color-block;
  align-items: flex-start;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  img {
    min-width: 88px;
    width: 88px;
    height: 50px;
  }

  .text {
    @include padL;
    width: 100%;
    display: flex;
    flex-direction: column;

    & * {
      @include noMarL;
    }

    h3 {
      @include hMarT;
      @include hMarB;
    }

    h4 {
      @include hMarT;
      @include hMarB;
      font-weight: normal;
    }
  }

  .controls {
    margin-right: 12px;
    display: flex;
    min-width: 100px;
    justify-content: flex-end;
    align-self: center;
  }
}

.dash-list {
  .dash-list-item {
    @include block;
    @include white-bkg;
    position: relative;
    cursor: pointer;
    min-height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;

    .info {
      display: flex;

      app-icon {
        @include white-bkg;
        min-width: 88px;
        height: 88px;
        border: solid 1px $color-text;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .text {
        @include padL;
        display: flex;
        flex-direction: column;

        h2 {
          @include noMar
        }

        h5 {
          @include noMarB;
          @include hMarT;
        }
      }
    }

    .controls {
      display: flex;

      .confirm app-icon {
        top: 0 !important;
      }
    }

    @media screen and (max-width: $break-small) {
      @include hMar;
      @include padT;
      @include hPadB;
      @include noPadL;
      @include noPadR;
      flex-direction: column;

      .info {
        flex-direction: column;

        .text {
          @include noPadL;
          text-align: center;

          h2 {
            @include noMarT;
          }
        }
      }

      .controls {
        @include marT;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}