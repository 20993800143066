@import 'vars';

.dropper-container {
  border: dashed 2px $color-warm-grey;
  position: relative;
  background-color: $color-block-light;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 100%;

  app-spinner {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    align-self: center;
    top: 0;
    display: flex;
    align-items: center;
  }
}

quill-editor {
  @include white-bkg;
  @include hMarT;
  display: block !important;
}

.app-form {
  @include pad;

  &>* {
    @include marT;
  }

  &:first-child {
    @include noMarT
  }

  @media only screen and (max-width: $break-small) {
    @include padT;
    @include padB;
    @include hPadL;
    @include hPadR;

    h3 {
      text-align: center
    }
  }
}

.closable {
  position: relative
}

.important-input {
  text-align: center !important;

  label {
    font-size: 16px;
    text-align: center;
  }

  .mdc-text-field__input {
    text-align: center;
  }
}

mat-form-field {
  width: 100%
}

.mdc-text-field--filled,
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-line-ripple {
  &::before {
    border-bottom-color: $color-input-border !important;
  }

  &::after {
    border-bottom-color: $color-text !important;
  }
}

.mdc-text-field--invalid .mdc-line-ripple::before {
  border-bottom-color: $color-error !important;
}

.mat-focused .mat-mdc-select-arrow {
  color: $color-text !important;
}

.mat-mdc-checkbox-checked .mdc-checkbox__background,
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mdc-slider__track--inactive {
  background-color: $color-audis !important;
}

.mdc-checkbox__background,
.mdc-radio__outer-circle,
.mat-mdc-radio-checked .mdc-radio__inner-circle,
.mdc-slider__track--active_fill,
.mdc-slider__thumb-knob {
  border-color: $color-audis !important;
}

mat-checkbox {
  @include brand-font;
  font-style: italic;
  color: $color-warm-grey;
  font-size: 13px;
  display: block;
  text-align: left;
}

label.mdc-label {
  @include brand-font;
  color: $color-text;
  font-size: 13px;
  white-space: normal;
}

.mat-mdc-form-field-hint.error,
.mat-mdc-form-field-hint.mat-error {
  color: $color-error !important;
}

.mat-mdc-form-field .mat-mdc-text-field-wrapper,
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  @include noPad;
}

.mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding: 24px 0 8px !important;
}

.important-input .mat-mdc-select-value {
  padding-left: 10px;
}

.mat-option {
  font-size: 15px !important;

  &.default {
    color: $color-warm-grey !important
  }
}

mat-chip,
mat-hint,
mat-error,
.mdc-list-item__primary-text,
.mat-mdc-form-field-label-wrapper,
.mat-mdc-form-field-infix,
.mat-mdc-select-value,
.mat-calendar,
.ql-container {
  @include brand-font;
}

.mat-mdc-option.mdc-list-item--selected .mdc-list-item__primary-text,
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-datepicker-toggle-active {
  color: $color-audis !important;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full .mat-pseudo-checkbox-checked::after {
  color: $color-white !important;
}

.mdc-list-item__primary-text {
  display: flex !important;
  line-height: 40px !important;
}

mat-chip app-icon {
  margin-top: -20px;
  padding-right: 9px;
}

.mat-select-search-input,
.ql-snow {
  background-color: $color-white !important;
}

.mat-select-search-clear {
  top: 3px !important;
  right: 3px !important;

  &.mat-mdc-icon-button.mat-mdc-button-base {
    width: 40px;
    height: 40px;
    padding: 0;
  }
}

.mat-calendar-table-header th,
.mat-calendar-body-cell-container,
.mat-calendar-body-label {
  border: none;
}

.mat-calendar-body-cell {
  @include noPad;
  background-color: transparent !important;
}

.mat-calendar-body-active .mat-calendar-body-today,
.mat-calendar-body-cell:hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: $color-white;
  background-color: $color-audis !important;
  opacity: 0.6;
}

.mat-calendar-body-selected {
  background-color: $color-audis !important;
  opacity: 1 !important;

  &:hover {
    opacity: 0.6;
  }
}

.mat-mdc-card.ngx-audio-player {
  flex-direction: row;
  min-width: auto;
  align-items: center;
}

.mdc-slider__thumb {
  top: 0;
}