/*
    "nearicons" is a icon font generated with icomoon.io
    
    How-to update "nearicon" font:
      - export the font from icomoon
      - copy nearicons.svg, nearicons.ttf, nearicons.woff inside the assets/fonts folder
      - IMPORTANT: copy the selection.json file inside the assets/font folder - this will allow to reload the icons inside icomoonn
      - copy the specific icon styles from styles.css to this file, preserving the general styles of this file
*/


@font-face {
  font-family: 'nearicons';
  src:
    url('/assets/nearicons/nearicons.ttf?y6l1dt') format('truetype'),
    url('/assets/nearicons/nearicons.woff?y6l1dt') format('woff'),
    url('/assets/nearicons/nearicons.svg?y6l1dt#nearicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nearicons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// ↓↓↓ COPY NEW ICON STYLES HERE ↓↓↓

.icon-edit-copy:before {
  content: "\e97a";
}
.icon-add_icon:before {
  content: "\e905";
}
.icon-add_member:before {
  content: "\e906";
}
.icon-add_tag:before {
  content: "\e907";
}
.icon-add_user:before {
  content: "\e908";
}
.icon-add:before {
  content: "\e909";
}
.icon-address:before {
  content: "\e90a";
}
.icon-android:before {
  content: "\e90b";
}
.icon-apple:before {
  content: "\e90c";
}
.icon-apps:before {
  content: "\e90d";
}
.icon-areabeacon:before {
  content: "\e90e";
}
.icon-attachment:before {
  content: "\e90f";
}
.icon-back_calendar:before {
  content: "\e910";
}
.icon-back_dash:before {
  content: "\e911";
}
.icon-back_list:before {
  content: "\e912";
}
.icon-back_map:before {
  content: "\e913";
}
.icon-beacon_places_menu:before {
  content: "\e914";
}
.icon-beacon_tag:before {
  content: "\e915";
}
.icon-big_contextual_help:before {
  content: "\e916";
}
.icon-broken_image:before {
  content: "\e917";
}
.icon-calendar_medium:before {
  content: "\e919";
}
.icon-calendar:before {
  content: "\e92e";
}
.icon-cancel_tag:before {
  content: "\e91a";
}
.icon-cancel:before {
  content: "\e91b";
}
.icon-click_or_drop:before {
  content: "\e91c";
}
.icon-close_accordion:before {
  content: "\e91d";
}
.icon-close_menu:before {
  content: "\e91e";
}
.icon-close:before {
  content: "\e91f";
}
.icon-cms_logout:before {
  content: "\e920";
}
.icon-code:before {
  content: "\e921";
}
.icon-confirm:before {
  content: "\e922";
}
.icon-contact:before {
  content: "\e923";
}
.icon-contextual_help:before {
  content: "\e924";
}
.icon-copy_small:before {
  content: "\e925";
}
.icon-coupon_emitted:before {
  content: "\e926";
}
.icon-coupon_medium:before {
  content: "\e927";
}
.icon-coupon_small:before {
  content: "\e928";
}
.icon-coupon:before {
  content: "\e929";
}
.icon-data_divider:before {
  content: "\e92a";
}
.icon-delete_member:before {
  content: "\e92b";
}
.icon-delete:before {
  content: "\e92c";
}
.icon-documentation:before {
  content: "\e92d";
}
.icon-draft:before {
  content: "\e939";
}
.icon-edit:before {
  content: "\e92f";
}
.icon-enter_place:before {
  content: "\e930";
}
.icon-enter:before {
  content: "\e931";
}
.icon-everybody:before {
  content: "\e932";
}
.icon-fileaudio:before {
  content: "\e933";
}
.icon-filled_circle:before {
  content: "\e934";
}
.icon-flag:before {
  content: "\e935";
}
.icon-funnel_clicklink:before {
  content: "\e936";
}
.icon-funnel_coupon_claimed:before {
  content: "\e937";
}
.icon-funnel_feedback_sent:before {
  content: "\e938";
}
.icon-funnel_sent_notification:before {
  content: "\e947";
}
.icon-geofence_tag:before {
  content: "\e93a";
}
.icon-globe:before {
  content: "\e93b";
}
.icon-help:before {
  content: "\e93c";
}
.icon-hide:before {
  content: "\e93d";
}
.icon-hourglass_trial_expired:before {
  content: "\e93e";
}
.icon-hourglass:before {
  content: "\e93f";
}
.icon-inapp_events_mapping:before {
  content: "\e941";
}
.icon-inclusion_cancel:before {
  content: "\e942";
}
.icon-indoor_floor:before {
  content: "\e943";
}
.icon-info:before {
  content: "\e944";
}
.icon-leave_place:before {
  content: "\e945";
}
.icon-left_arrow:before {
  content: "\e946";
}
.icon-link:before {
  content: "\e95b";
}
.icon-logout:before {
  content: "\e948";
}
.icon-long_sketched_arrow:before {
  content: "\e949";
}
.icon-map:before {
  content: "\e94a";
}
.icon-marker_dx:before {
  content: "\e94b";
}
.icon-marker_sx:before {
  content: "\e94c";
}
.icon-media_gallery:before {
  content: "\e94d";
}
.icon-menu_settings:before {
  content: "\e94e";
}
.icon-menu-stat:before {
  content: "\e94f";
}
.icon-next_calendar:before {
  content: "\e950";
}
.icon-next_list:before {
  content: "\e951";
}
.icon-notification_and_content:before {
  content: "\e952";
}
.icon-notification_opened:before {
  content: "\e953";
}
.icon-notification_received:before {
  content: "\e954";
}
.icon-now:before {
  content: "\e955";
}
.icon-ok:before {
  content: "\e956";
}
.icon-oops:before {
  content: "\e957";
}
.icon-open_profile:before {
  content: "\e958";
}
.icon-pdf:before {
  content: "\e959";
}
.icon-picture:before {
  content: "\e95a";
}
.icon-pin:before {
  content: "\e978";
}
.icon-poll:before {
  content: "\e95c";
}
.icon-pricing:before {
  content: "\e95d";
}
.icon-product_list:before {
  content: "\e95e";
}
.icon-push_settings:before {
  content: "\e95f";
}
.icon-raggio:before {
  content: "\e960";
}
.icon-ranging:before {
  content: "\e961";
}
.icon-rating:before {
  content: "\e962";
}
.icon-recipe:before {
  content: "\e963";
}
.icon-remove_member:before {
  content: "\e964";
}
.icon-reuse:before {
  content: "\e965";
}
.icon-right_arrow:before {
  content: "\e966";
}
.icon-schedule:before {
  content: "\e967";
}
.icon-sdk:before {
  content: "\e968";
}
.icon-search:before {
  content: "\e969";
}
.icon-selected_contextual_help:before {
  content: "\e96a";
}
.icon-sent:before {
  content: "\e96b";
}
.icon-open:before {
  content: "\e96c";
}
.icon-show:before {
  content: "\e96d";
}
.icon-sketched_arrow_dx:before {
  content: "\e96e";
}
.icon-sketched_arrow_sx:before {
  content: "\e96f";
}
.icon-social:before {
  content: "\e970";
}
.icon-somebody:before {
  content: "\e971";
}
.icon-star_line:before {
  content: "\e972";
}
.icon-star_small:before {
  content: "\e973";
}
.icon-star:before {
  content: "\e974";
}
.icon-stats_menu:before {
  content: "\e976";
}
.icon-stats:before {
  content: "\e977";
}
.icon-tell_friend:before {
  content: "\e9e5";
}
.icon-testdevice_icon:before {
  content: "\e9e6";
}
.icon-text:before {
  content: "\e9e7";
}
.icon-trigger:before {
  content: "\e9e8";
}
.icon-type_booleano:before {
  content: "\e9e9";
}
.icon-type_enumeration:before {
  content: "\e9ea";
}
.icon-type_number:before {
  content: "\e9eb";
}
.icon-typo_data:before {
  content: "\e9ec";
}
.icon-update:before {
  content: "\e9ed";
}
.icon-user_management:before {
  content: "\e9ee";
}
.icon-userdatamapping:before {
  content: "\e9ef";
}
.icon-video:before {
  content: "\e9f0";
}
.icon-what:before {
  content: "\e9f1";
}
.icon-who_settings:before {
  content: "\e9f2";
}
.icon-who:before {
  content: "\e9f3";
}
.icon-worried:before {
  content: "\e9f4";
}
.icon-zoomin_map:before {
  content: "\e9f5";
}
.icon-zoomout_map:before {
  content: "\e9f6";
}
.icon-text_small:before {
  content: "\e9f9";
}
.icon-type_enumeration_small:before {
  content: "\e9fd";
}
.icon-number_small:before {
  content: "\e9ff";
}
.icon-user_segmentation_editing:before {
  content: "\ea04";
}
.icon-museum:before {
  content: "\e900";
}
.icon-qrcode:before {
  content: "\e979";
}
.icon-new-tab:before {
  content: "\ea7e";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-qrcode:before {
  content: "\e979";
}
.icon-new-tab:before {
  content: "\ea7e";
}