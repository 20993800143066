@import url('https://fonts.googleapis.com/css?family=Asap:400,400i,500,600|Dekko&display=swap');
@import "nearicons";
@import "vars";
@import 'styles/dialog.scss';
@import 'styles/input.scss';
@import 'styles/page-structure.scss';
@import 'styles/list.scss';
@import 'styles/tab.scss';

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  transition: background-color 5000s ease-in-out 0s;
}

html,
body {
  @include brand-font;
  margin: 0;
  font-size: 15px;
  color: $color-text !important;
}

* {
  letter-spacing: normal !important;
}

h1 {
  font-size: 35px;
  margin: 15px 0;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 13px;
  font-weight: normal;
}

a {
  color: $color-audis;
}

.help-font {
  @include help-font;
  font-size: 16px;
}

.noMar {
  @include noMar
}

.noMarB {
  @include noMarB
}

.hMarT {
  @include hMarT;
}

.hMarB {
  @include hMarB;
}

.marT {
  @include marT;
}

.marR {
  @include marR;
}

.marB {
  @include marB;
}

.xMarT {
  @include xMarT;
}

.xMarB {
  @include xMarB;
}

.hPad {
  @include hPad;
}

.padB {
  @include padB;
}

.center-text {
  text-align: center !important
}