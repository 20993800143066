// colors
$color-audis: #d41367;
$color-audis-light: #ff668f;
$color-audis-dark: #7e445a;
$color-white: #FFFFFF;
$color-text: #333333;
$color-warm-grey: #979797;
$color-input-border: #E1E1E1;
$color-light-background: #f0f0f0;
$color-block: #f2f2f2;
$color-block-light: #f8f8f8;
$color-error: #F44336;
$color-positive: #68c600;
$color-warning: #ffedcc;

$x3 : 36px;
$x2 : 20px;
$x : 8px;

$break-extrasmall: 363px;
$break-small: 576px;
$break-large: 768px;
$break-extralarge: 1200px;
$toolbar-height: 66px;

$block-pad: 0 15%;
$block-pad-xl: 0 7%;
$block-pad-l: 0 5%;
$block-pad-sm: 0 $x;

@mixin brand-font {
  font-family: 'Asap', sans-serif !important;
}

@mixin help-font {
  font-family: 'Dekko', sans-serif;
  cursor: default;
  user-select: none;
}

@mixin noMar {
  margin: 0 !important;
  -webkit-margin-after: 0 !important;
}

@mixin noMarT {
  margin-top: 0 !important
}

@mixin noMarB {
  margin-bottom: 0 !important
}

@mixin noMarL {
  margin-left: 0 !important
}

@mixin hMarT {
  margin-top: $x !important
}

@mixin hMarR {
  margin-right: $x !important
}

@mixin hMarB {
  margin-bottom: $x !important
}

@mixin hMarL {
  margin-left: $x !important
}

@mixin hMar {
  margin: $x !important
}

@mixin marT {
  margin-top: $x2 !important
}

@mixin marR {
  margin-right: $x2 !important
}

@mixin marB {
  margin-bottom: $x2 !important
}

@mixin marL {
  margin-left: $x2 !important
}

@mixin xMarT {
  margin-top: $x3 !important
}

@mixin xMarB {
  margin-bottom: $x3 !important
}

@mixin noPad {
  padding: 0 !important
}

@mixin noPadR {
  padding-right: 0 !important
}

@mixin noPadL {
  padding-left: 0 !important
}

@mixin hPad {
  padding: $x !important
}

@mixin hPadB {
  padding-bottom: $x !important
}

@mixin hPadR {
  padding-right: $x !important
}

@mixin hPadL {
  padding-left: $x !important
}

@mixin pad {
  padding: $x2 !important
}

@mixin padT {
  padding-top: $x2 !important
}

@mixin padB {
  padding-bottom: $x2 !important
}

@mixin padR {
  padding-right: $x2 !important
}

@mixin padL {
  padding-left: $x2 !important
}

@mixin xPad {
  padding: $x3 !important
}

@mixin page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@mixin block {
  padding: $block-pad;

  @media only screen and (max-width: $break-extralarge) {
    padding: $block-pad-xl;
  }

  @media only screen and (max-width: $break-large) {
    padding: $block-pad-l;
  }

  @media only screen and (max-width: $break-small) {
    padding: $block-pad-sm;
  }
}

@mixin white-bkg {
  background-color: $color-white;

  .help-font {
    color: $color-text
  }
}

@mixin dialog-component {
  @include padT;
  @include padL;
  @include padR;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  h2 {
    color: $color-error
  }

  p {
    @include help-font
  }
}