.mat-mdc-tab-group {
  flex-direction: row !important;

  .mat-mdc-tab-header {
    width: 100%;
    border: none
  }

  .mdc-tab-indicator__content--underline {
    display: none
  }

  .mat-mdc-tab-labels {
    box-shadow: inset 0 -2px 3px 0 rgba(0, 0, 0, 0.07);
  }

  .mat-mdc-tab {
    min-width: 160px;
    flex-grow: 0 !important;
  }

  .mdc-tab__text-label {
    justify-content: center;
    font-weight: bold;
  }

  .mdc-tab--active {
    @include white-bkg;
    opacity: 1;
    overflow: hidden;

    .mdc-tab__text-label {
      color: $color-text !important;
    }
  }

  .mat-mdc-tab-body-wrapper {
    display: none !important; // attention: this will prevent standard tabs usage
  }
}