@import 'vars';

.mat-mdc-dialog-container {
  @include noPad;
  max-height: 70vh !important;

  @media screen and (max-width: $break-small) {
    width: 350px !important;
    max-width: 100%;
  }
}

.dialog-controls {
  @include xMarT;
  display: flex;
  flex-direction: column;
  align-items: center;

  app-button {
    @include marB
  }
}