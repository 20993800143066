@import 'vars';

.toolbar {
  display: flex;
  height: $toolbar-height;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: $block-pad;

  @media only screen and (max-width: $break-extralarge) {
    margin: $block-pad-xl;
  }

  @media only screen and (max-width: $break-large) {
    margin: $block-pad-l;
  }

  @media only screen and (max-width: $break-small) {
    margin: $block-pad-sm;
  }

  .right {
    position: absolute;
    right: 0;
  }
}

.block {
  @include block
}

.tooltip .mdc-tooltip__surface {
  @include brand-font;
  font-style: italic;
  background-color: $color-audis-dark !important;
  letter-spacing: 0.8px;
  font-size: 13px !important;
  font-weight: 400;
  border-radius: 3px;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  box-sizing: border-box;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.drag-preview {
  background-color: $color-audis-dark;
  color: $color-white;
  padding: 10px;
  border-radius: 4px;
}